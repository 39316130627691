import React, { useState } from 'react';
import Layout from 'components/Layout';
import NoSsr from '@material-ui/core/NoSsr';
import { ReactQueryConfigProvider, useMutation } from 'react-query';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Button from 'components/inputs/ButtonM';
import { useBranchId } from 'utils/customHooks';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetch from 'utils/fetch';

const Inventory = (props) => (
  <Layout {...props} hideDateFilter>
    <NoSsr>
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <InventoryManagement />
      </ReactQueryConfigProvider>
    </NoSsr>
  </Layout>
);

function InventoryManagement() {
  const branchId = useBranchId();
  const [dateRange, setDateRange] = useState([]);

  return (
    <div class="m-8 ml-16">
      <div class="flex">
        <Button variant="text" href={`/api/falcon/inventory/transaction/sample?nodeId=${branchId}`}>
          Sample
        </Button>
        <div class="ml-4">
          <InventoryUploader nodeId={branchId} />
        </div>
      </div>
      <div class="flex mt-4">
        <div class="w-96 mr-4">
          <DateTimePicker onChange={setDateRange} />
        </div>
        <Button
          disabled={branchId === -1 || !dateRange[0] || !dateRange[1]}
          href={`/api/falcon/inventory/snapshot?nodeId=${branchId}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`}
        >
          Download
        </Button>
      </div>
    </div>
  );
}

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: 'inherit',
};

function InventoryUploader({ nodeId, onSuccess = () => {} }) {
  const [uploadOrder, { status }] = useMutation(
    (data) =>
      fetch({
        url: `/falcon/inventory/transaction?nodeId=${nodeId}`,
        method: 'POST',
        data,
      }),
    {
      onSuccess: onSuccess,
    }
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      const data = new FormData();
      data.append('file', files[0]);
      uploadOrder(data);
    },
  });

  return (
    <div class="container flex-grow h-40 items-center mb-2">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
        {status === 'loading' && (
          <div class="mt-3">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Inventory;
